import React, { useEffect, useState } from 'react';
import MainLayout from "../layouts/MainLayout";
import { useParams } from "react-router";
import { CCard, CCardBody, CCardTitle, CCol, CRow, CSpinner, CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow, CWidgetStatsF } from "@coreui/react";
import jiraClient from "../services/JiraClient";
import Issue from "../models/jira/Issue";
import { CIcon } from "@coreui/icons-react";
import { cilAvTimer, cilCalendar } from '@coreui/icons';
import { CustomField } from "../models/jira/CustomField";
import { StatusCategory } from "../models/jira/StatusCategory";
import CorrespondanceCard from "../components/IssuePage/CorrespondanceCard";
import ProgressRow from "../components/IssuePage/ProgressRow";
import IssueNotFound from "../components/IssuePage/IssueNotFound";

type IssueDatas = {
    loaded: boolean,
    issue?: Issue,
    children?: Issue[]
}

const loadIssue = async (issueKey: string): Promise<IssueDatas> => {
    const datas: IssueDatas = {
        loaded: true
    }

    const issue = await jiraClient.getIssue(issueKey)

    if (!issue)
        return datas

    datas.issue = issue
    datas.children = await jiraClient.getChildren(issueKey)

    return datas
}

const IssuePage : React.FC = () => {
    const [datas, setDatas] = useState<IssueDatas>({loaded: false})

    const issueKey = useParams().issueKey

    if (!issueKey) {
        return (
            <>
                <IssueNotFound />
            </>
        )
    }

    useEffect(() => {
        setDatas({loaded: false})
        loadIssue(issueKey).then(setDatas)
    }, [issueKey]);

    if (!datas.loaded)
        return (
            <MainLayout>
                <CCol className='text-center'>
                    <CSpinner className='m-5' style={{ width: '3rem', height: '3rem'}}/>
                </CCol>
            </MainLayout>
        )

    if (!datas.issue)
        return (
            <IssueNotFound/>
        )

    const children = datas.children ?? []
    const childrenDone = children.filter((i) => i.fields.status.statusCategory.id == StatusCategory.DONE)
    const childrenInProgress = children.filter((i) => i.fields.status.statusCategory.id == StatusCategory.IN_PROGRESS)

    const childrenGlobalEffort = children.reduce((acc, issue) => {
        return acc + issue.fields[CustomField.STORYPOINT]
    }, 0)

    const childrenDoneEffort = childrenDone.reduce((acc, issue) => {
        return acc + issue.fields[CustomField.STORYPOINT]
    }, 0)

    const childrenProgressEffort = childrenInProgress.reduce((acc, issue) => {
        return acc + issue.fields[CustomField.STORYPOINT]
    }, 0)

    const doneProgress = childrenGlobalEffort ? childrenDoneEffort / childrenGlobalEffort : 0;
    const doneAndOnGoingProgress = childrenGlobalEffort ? (childrenProgressEffort + childrenDoneEffort) / childrenGlobalEffort : 0;
    const daysSpent = datas.issue.fields.aggregateprogress.total / 3600 / 8
    const estimatedDoneSpent = doneProgress != 0 ? daysSpent / doneProgress : 0
    const estimatedProgressSpent = doneAndOnGoingProgress != 0 ? daysSpent / doneAndOnGoingProgress : 0

    return (
        <>
            <MainLayout>
                <CRow>
                    <CCol lg={8}>
                        <CCard>
                            <CCardBody>
                                <CCardTitle className="mb-4">
                                    Progress for issue {datas.issue.key} : ${datas.issue.fields.summary}
                                </CCardTitle>
                                <CRow>
                                    <CCol xs={6}>
                                        <CWidgetStatsF
                                            className="mb-3"
                                            color="primary"
                                            icon={<CIcon icon={cilAvTimer} height={24} />}
                                            padding={false}
                                            title="Time spent"
                                            value={`${Math.round(daysSpent * 10) / 10} days`}/>
                                    </CCol>
                                    <CCol xs={6}>
                                        <CWidgetStatsF
                                            className="mb-3"
                                            color="warning"
                                            icon={<CIcon icon={cilCalendar} height={24} />}
                                            padding={false}
                                            title="Estimated budget needed"
                                            value={ estimatedDoneSpent != 0 && estimatedProgressSpent != 0 ?
                                                (estimatedProgressSpent != estimatedDoneSpent ?
                                                    `Between ${Math.round(estimatedProgressSpent * 10) / 10} and ${Math.round(estimatedDoneSpent * 10) / 10} days`
                                                    : `${Math.round(estimatedDoneSpent * 10) / 10} days`)
                                                : 'NA'}/>
                                    </CCol>
                                </CRow>
                                <CTable bordered>
                                    <CTableHead color="light">
                                        <CTableRow>
                                            <CTableHeaderCell></CTableHeaderCell>
                                            <CTableHeaderCell>Total</CTableHeaderCell>
                                            <CTableHeaderCell>Done</CTableHeaderCell>
                                            <CTableHeaderCell>In Progress</CTableHeaderCell>
                                            <CTableHeaderCell>Remaining</CTableHeaderCell>
                                            <CTableHeaderCell colSpan={2}>Progress</CTableHeaderCell>
                                        </CTableRow>
                                    </CTableHead>
                                    <CTableBody>
                                        <ProgressRow label="Stories" total={children.length} done={childrenDone.length} inProgress={childrenInProgress.length}></ProgressRow>
                                        <ProgressRow label="Story points" total={childrenGlobalEffort} done={childrenDoneEffort} inProgress={childrenProgressEffort}></ProgressRow>
                                    </CTableBody>
                                </CTable>
                            </CCardBody>
                        </CCard>
                    </CCol>
                    <CCol lg={4}>
                        <CorrespondanceCard daysSpent={daysSpent} estimatedDoneSpent={estimatedDoneSpent} estimatedProgressSpent={estimatedProgressSpent}></CorrespondanceCard>
                    </CCol>
                </CRow>
            </MainLayout>
        </>
    );
}

export default IssuePage;
