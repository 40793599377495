import React, { useState } from 'react';
import { CButton, CCard, CCardBody, CCardTitle, CCol, CContainer, CForm, CFormInput } from "@coreui/react";
import Credentials from "../models/Credentials";
import { useDispatch } from "react-redux";
import { updateCredentials } from "../stores/credentials/Action";
import { useNavigate } from "react-router";

const Login : React.FC = () => {
    const [credentials, setCredentials] = useState<Credentials>({email: '', apiToken: ''})
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setCredentials(prevData => ({...prevData, [name]: value}))
    }

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            dispatch(updateCredentials(credentials))
            const queryParameters = new URLSearchParams(window.location.search)
            const redirectUrl = queryParameters.get("redirect")
            navigate(redirectUrl ?? '/')
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <CContainer fluid>
                <div className='row justify-content-center mt-sm-1 mt-sm-3 mt-lg-5'>
                    <CCol sm={12} md={6} lg={4} >
                        <CCard>
                            <CCardBody>
                                <CCardTitle>Log in</CCardTitle>
                                <CForm className="row g-3" onSubmit={handleSubmit}>
                                    <CCol sm={12}>
                                        <CFormInput placeholder="Email" aria-label="Email" label="Email" name="email" value={credentials.email} onChange={handleChange} required />
                                    </CCol>
                                    <CCol sm={12}>
                                        <CFormInput placeholder="Api Token" aria-label="Api Token" type="password" label="Api Token" name="apiToken" value={credentials.apiToken} onChange={handleChange} required />
                                    </CCol>
                                    <div className="col-sm-12 d-grid">
                                        <CButton color="primary" type="submit">Sign in</CButton>
                                    </div>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </div>
            </CContainer>
        </>
    );
}

export default Login;
